import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Fragment, useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clubsService from '../../services/clubsService'
import { PAGE_ALL } from '../../services/commonService'
import playersService from '../../services/playersService'
import statisticsService from '../../services/statisticsService'
import { transitionWrapper } from '../../services/transitionWrapper'
import { addClusterStatistics, addLeagueStatistics } from '../../slices/statisticsSlice'
import UserCurrency from '../currency/userCurrency'
import { currencyUomRenderer, currencyUomWithPercRenderer, decimalNumberRenderer } from '../data/formatters/dataFormatters'
import TranslatedContent from '../translations/translatedContent'
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

const formTextFieldSx = {
  height: 25,
  backgroundColor: '#FFFFFF',
  borderRadius: 25,
  px: 1,
  borderBottom: 0,
  '& :before': {
    borderBottom: 0
  },
  '& ::before': {
    borderBottom: 0
  },
  '& .MuiInputBase-root': {
    fontSize: '1.2rem'
  },
  '& .MuiInputBase-root:before': {
    borderBottom: 0
  },
  '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: 0
  },
  '& :hover:not(.Mui-disabled, .Mui-error)::before': {
    borderBottom: 0
  },
  '& :after': {
    borderBottom: 0
  },
  '& ::after': {
    borderBottom: 0
  },
  '& .MuiInputBase-root:after': {
    borderBottom: 0
  },
  '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):after': {
    borderBottom: 0
  },
  '& :hover:not(.Mui-disabled, .Mui-error)::after': {
    borderBottom: 0
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'unset'
  }
}

const TransferSimulatorSelect = (props) => {

  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    props.onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Select
      variant='standard'
      size='small'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            mt: 1
          },
          '& li': {
            fontSize: '1.2rem'
          }
        }
      }}
      inputProps={{
        sx: {
          textAlign: 'right',
          borderBottom: 0,
          fontSize: '1.2rem',
          '&:focus': { backgroundColor: 'transparent' }
        }
      }}
      sx={{
        ...props.sx,
        '&:before': { borderBottom: 0 },
        '&:hover:not(.Mui-disabled, .Mui-error)::before': { borderBottom: 0 },
        '&:after': { borderBottom: 0 }
      }}>
      {props.children}
    </Select >
  )
}

const TransferSimulatorForm = (props) => {

  const contractPeriodOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const performanceOptions = [2, 1.5, 1.2, 1, 0.5, 0.2]
  const performanceLabelMap = {
    2: { label: 'exploitPerformance' },
    1.5: { label: 'overPerformance' },
    1.2: { label: 'growthPerformance' },
    1: { label: 'continuityPerformance' },
    0.5: { label: 'underPerformance' },
    0.2: { label: 'failurePerformance' }
  }
  const intensityOptions = [0.75, 0.4, 0.25]
  const intensityLabelMap = {
    0.75: { label: 'startingPlayer' },
    0.4: { label: 'backupPlayer' },
    0.25: { label: 'fringePlayer' }
  }

  const [formValues, setFormValues] = useState(props.values)
  const [validationResult, setValidationResult] = useState(props.validationResult)

  useEffect(() => {
    setFormValues(props.values)
  }, [props.values])

  useEffect(() => {
    props.onChange(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  useEffect(() => {
    setValidationResult(props.validationResult)
  }, [props.validationResult])

  return (
    <TableContainer>
      <Table size='small' sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='acquiringClub' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='acquiringClub' defaultValue='Acquiring Club' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <AcquiringClubAutocomplete validationResult={validationResult} onChange={props.setDestinationClub} />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='contractPeriod' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='contractPeriod' defaultValue='Contract Period' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TransferSimulatorSelect
                  value={formValues.contractDuration?.toString()}
                  onChange={(value) => setFormValues({ ...formValues, contractDuration: Number(value) })}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.contractDuration) ? '2px solid red' : 0 }}>
                  {contractPeriodOptions.map(option => (
                    <MenuItem key={option} id={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TransferSimulatorSelect>
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}>Y</Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='yearlyNetSalary' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='yearlyNetSalary' defaultValue='Yearly Net Salary' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  value={formValues.salaryCurrent?.toString()}
                  onChange={(e) => setFormValues({ ...formValues, salaryCurrent: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.salaryCurrent) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}><UserCurrency /></Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='purchaseFee' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='purchaseFee' defaultValue='Purchase Fee' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  value={formValues.transferPrice?.toString()}
                  onChange={(e) => setFormValues({ ...formValues, transferPrice: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.transferPrice) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}><UserCurrency /></Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedPerformance' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedPerformance' defaultValue='Expected Performance' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.performance?.toString()}
                onChange={(value) => setFormValues({ ...formValues, performance: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.performance) ? '2px solid red' : 0 }}>
                {performanceOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={performanceLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedUse' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedUse' defaultValue='Expected Use' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.intensity?.toString()}
                onChange={(value) => setFormValues({ ...formValues, intensity: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.intensity) ? '2px solid red' : 0 }}>
                {intensityOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={intensityLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='transactionCosts' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='transactionCosts' defaultValue='Transaction Costs' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box sx={{ display: 'flex', my: { xs: .5, sm: 0 } }}>
                
                  <TextField
                    variant='standard'
                    size='small'
                    type='number'
                    placeholder='Acquiring club'
                    value={formValues.commissionBuyer?.toString()}
                    onChange={(e) => setFormValues({ ...formValues, commissionBuyer: Number(e.target.value) })}
                    inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                    sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.commissionBuyer) ? '2px solid red' : 0 }} />
                  <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', mx: 1 }}><UserCurrency /></Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: { xs: .5, sm: 0 } }}>
                  <TextField
                    variant='standard'
                    size='small'
                    type='number'
                    placeholder='Releasing club'
                    value={formValues.commissionSeller?.toString()}
                    onChange={(e) => setFormValues({ ...formValues, commissionSeller: Number(e.target.value) })}
                    inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                    sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.commissionSeller) ? '2px solid red' : 0 }} />
                  <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', mx: 1 }}><UserCurrency /></Typography>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const RenewSimulatorForm = (props) => {

  const contractPeriodOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const performanceOptions = [2, 1.5, 1.2, 1, 0.5, 0.2]
  const performanceLabelMap = {
    2: { label: 'exploitPerformance' },
    1.5: { label: 'overPerformance' },
    1.2: { label: 'growthPerformance' },
    1: { label: 'continuityPerformance' },
    0.5: { label: 'underPerformance' },
    0.2: { label: 'failurePerformance' }
  }
  const intensityOptions = [0.75, 0.4, 0.25]
  const intensityLabelMap = {
    0.75: { label: 'startingPlayer' },
    0.4: { label: 'backupPlayer' },
    0.25: { label: 'fringePlayer' }
  }

  const [formValues, setFormValues] = useState(props.values)
  const [validationResult, setValidationResult] = useState(props.validationResult)

  useEffect(() => {
    setFormValues(props.values)
  }, [props.values])

  useEffect(() => {
    props.onChange(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  useEffect(() => {
    setValidationResult(props.validationResult)
  }, [props.validationResult])

  return (
    <TableContainer>
      <Table size='small' sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          {/* <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='acquiringClub' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='acquiringClub' defaultValue='Acquiring Club' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <AcquiringClubAutocomplete validationResult={validationResult} onChange={props.setDestinationClub} />
              </Box>
            </TableCell>
          </TableRow> */}
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='contractPeriod' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='contractPeriod' defaultValue='Contract Period' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TransferSimulatorSelect
                  value={formValues.contractDuration?.toString()}
                  onChange={(value) => setFormValues({ ...formValues, contractDuration: Number(value) })}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.contractDuration) ? '2px solid red' : 0 }}>
                  {contractPeriodOptions.map(option => (
                    <MenuItem key={option} id={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TransferSimulatorSelect>
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}>Y</Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='yearlyNetSalary' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='yearlyNetSalary' defaultValue='Yearly Net Salary' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  value={formValues.salaryCurrent?.toString()}
                  onChange={(e) => setFormValues({ ...formValues, salaryCurrent: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.salaryCurrent) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}><UserCurrency /></Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedPerformance' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedPerformance' defaultValue='Expected Performance' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.performance?.toString()}
                onChange={(value) => setFormValues({ ...formValues, performance: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.performance) ? '2px solid red' : 0 }}>
                {performanceOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={performanceLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedUse' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedUse' defaultValue='Expected Use' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.intensity?.toString()}
                onChange={(value) => setFormValues({ ...formValues, intensity: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.intensity) ? '2px solid red' : 0 }}>
                {intensityOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={intensityLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          {/* <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='transactionCosts' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='transactionCosts' defaultValue='Transaction Costs' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box sx={{ display: 'flex', my: { xs: .5, sm: 0 } }}>
                  <TextField
                    variant='standard'
                    size='small'
                    type='number'
                    placeholder='Acquiring club'
                    value={formValues.commissionBuyer?.toString()}
                    onChange={(e) => setFormValues({ ...formValues, commissionBuyer: Number(e.target.value) })}
                    inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                    sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.commissionBuyer) ? '2px solid red' : 0 }} />
                  <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', mx: 1 }}><UserCurrency /></Typography>
                </Box>
              </Box>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const LoanSimulatorForm = (props) => {

  function generateContractPeriodsOptions(remainingMonthsOfContract) {
    // Check if the input is a valid positive integer
    if (typeof remainingMonthsOfContract !== 'number' || remainingMonthsOfContract <= 0 || !Number.isInteger(remainingMonthsOfContract)) {
      console.error('Please provide a valid positive integer.');
      return [];
    }

    // Convert months to years and round down
    const remainingYears = Math.floor(remainingMonthsOfContract / 12);
   
    // If the remaining years are greater than 1, generate an array of numbers less than (years - 1)
    if (remainingYears > 1) {
      const numbersArray = [];
      for (let i = 1; i <= remainingYears - 1; i++) {
        numbersArray.push(i.toString());
      }
      return numbersArray;
    } else {
      // If the remaining years are less than or equal to 1, return an array with a single element: 0
      return [0];
    }
}
 
  const contractPeriodOptions = generateContractPeriodsOptions(props?.player.remainingMonthsOfContract)

  const performanceOptions = [2, 1.5, 1.2, 1, 0.5, 0.2]
  const performanceLabelMap = {
    2: { label: 'exploitPerformance' },
    1.5: { label: 'overPerformance' },
    1.2: { label: 'growthPerformance' },
    1: { label: 'continuityPerformance' },
    0.5: { label: 'underPerformance' },
    0.2: { label: 'failurePerformance' }
  }
  const intensityOptions = [0.75, 0.4, 0.25]
  const intensityLabelMap = {
    0.75: { label: 'startingPlayer' },
    0.4: { label: 'backupPlayer' },
    0.25: { label: 'fringePlayer' }
  }

  const [formValues, setFormValues] = useState(props.values)
  const [validationResult, setValidationResult] = useState(props.validationResult)
  
  useEffect(() => {
    
    setFormValues(props.values)
  }, [props.values])
 
  useEffect(() => {
    props.onChange(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  useEffect(() => {
    setValidationResult(props.validationResult)
  }, [props.validationResult])

  return (
    <TableContainer>
      <Table size='small' sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='acquiringClub' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='acquiringClub' defaultValue='Acquiring Club' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <AcquiringClubAutocomplete validationResult={validationResult} onChange={props.setDestinationClub} />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='contractPeriod' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='contractPeriod' defaultValue='Contract Period' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TransferSimulatorSelect
                  value={formValues.contractDuration?.toString()}
                  onChange={(value) => setFormValues({ ...formValues, contractDuration: Number(value) })}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.contractDuration) ? '2px solid red' : 0 }}>
                  {contractPeriodOptions.length > 0 &&
                    contractPeriodOptions.map(option => (
                      <MenuItem key={option} id={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TransferSimulatorSelect>
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}>Y</Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='yearlyNetSalary' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='yearlyNetSalary' defaultValue='Yearly Net Salary' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  value={formValues.salaryCurrent?.toString()}
                  onChange={(e) => setFormValues({ ...formValues, salaryCurrent: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.salaryCurrent) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}><UserCurrency /></Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>

          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='yearlyNetSalary' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='percSalaryPaid' defaultValue='Salary paid' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  value={formValues.percSalaryPaid?.toString()}
                  onChange={(e) => setFormValues({ ...formValues, percSalaryPaid: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.percSalaryPaid) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}>%</Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>

          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='loanFee' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='loanFee' defaultValue='Loan fee' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex' }}>
                <TextField
                  variant='standard'
                  size='small'
                  type='number'
                  defaultValue={0}
                  onChange={(e) => setFormValues({ ...formValues, transferPrice: Number(e.target.value) })}
                  inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                  sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.transferPrice) ? '2px solid red' : 0 }} />
                <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', ml: 1 }}><UserCurrency /></Typography>
                <Typography sx={{ display: 'none', color: '#FFFFFF', fontSize: '1.6rem', ml: 1 }}>
                  <Box component='span'>Max</Box>&nbsp;
                  <Box component='span'>???</Box>&nbsp;
                  <Box component='span'><UserCurrency /></Box>
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedPerformance' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedPerformance' defaultValue='Expected Performance' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.performance?.toString()}
                onChange={(value) => setFormValues({ ...formValues, performance: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.performance) ? '2px solid red' : 0 }}>
                {performanceOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={performanceLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='expectedUse' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='expectedUse' defaultValue='Expected Use' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <TransferSimulatorSelect
                value={formValues.intensity?.toString()}
                onChange={(value) => setFormValues({ ...formValues, intensity: Number(value) })}
                sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.intensity) ? '2px solid red' : 0 }}>
                {intensityOptions.map(option => (
                  <MenuItem key={option} id={option} value={option}>
                    <TranslatedContent code={intensityLabelMap[option].label} />
                  </MenuItem>
                ))}
              </TransferSimulatorSelect>
            </TableCell>
          </TableRow>
          {/* <TableRow sx={{ height: 45 }}>
            <TableCell sx={{ p: { xs: 0, md: 1 } }}>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={<TranslatedContent code='transactionCosts' description />}>
                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    <TranslatedContent code='transactionCosts' defaultValue='Transaction Costs' />
                  </Typography>
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell sx={{ p: 0 }}>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Box sx={{ display: 'flex', my: { xs: .5, sm: 0 } }}>
                  <TextField
                    variant='standard'
                    size='small'
                    type='number'
                    placeholder='Acquiring club'
                    value={formValues.commissionBuyer?.toString()}
                    onChange={(e) => setFormValues({ ...formValues, commissionBuyer: Number(e.target.value) })}
                    inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                    sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.commissionBuyer) ? '2px solid red' : 0 }} />
                  <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', mx: 1 }}><UserCurrency /></Typography>
                </Box>
                <Box sx={{ display: 'flex', mb: { xs: .5, sm: 0 } }}>
                  <TextField
                    variant='standard'
                    size='small'
                    type='number'
                    placeholder='Releasing club'
                    value={formValues.commissionSeller?.toString()}
                    onChange={(e) => setFormValues({ ...formValues, commissionSeller: Number(e.target.value) })}
                    inputProps={{ sx: { textAlign: 'end', px: .5 } }}
                    sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.commissionSeller) ? '2px solid red' : 0 }} />
                  <Typography sx={{ color: '#F4D35E', fontSize: '1.6rem', mx: 1 }}><UserCurrency /></Typography>
                </Box>
              </Box>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const AcquiringClubAutocomplete = (props) => {

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [acquiringClubValue, setAcquiringClubValue] = useState(null)
  const [acquiringClubInputValue, setAcquiringClubInputValue] = useState('')
  const [acquiringClubOptions, setAcquiringClubOptions] = useState([])
  const [validationResult, setValidationResult] = useState(props.validationResult)

  useEffect(() => {
    props.onChange(acquiringClubValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquiringClubValue])

  useEffect(() => {
    let active = true
    if (acquiringClubInputValue === '') {
      setAcquiringClubOptions([])
      return undefined
    }
    const potentialPriceSort = {
      field: 'potentialPrice',
      sort: 'desc'
    }
    const timeout = setTimeout(() => {
      clubsService.getClubs(userToken, dispatch, PAGE_ALL, { name: acquiringClubInputValue }, potentialPriceSort)
        .then(res => {
          if (active && res.status === 200) {
            setAcquiringClubOptions(res.data.data)
          }
        })
        .catch(err => console.error(err))
    }, 250)
    return () => {
      active = false
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, acquiringClubValue, acquiringClubInputValue])


  useEffect(() => {
    setValidationResult(props.validationResult)
  }, [props.validationResult])

  return (
    <Autocomplete
      sx={{ ...formTextFieldSx, width: '100%', border: Boolean(validationResult.destinationClubId) ? '2px solid red' : 0 }}
      freeSolo
      getOptionLabel={(club) => club.shortName}
      options={acquiringClubOptions}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      value={acquiringClubValue}
      noOptionsText='No Clubs'
      onChange={(_event, newValue) => {
        setAcquiringClubOptions([])
        setAcquiringClubValue(newValue)
      }}
      onInputChange={(_event, newInputValue) => {
        setAcquiringClubInputValue(newInputValue)
      }}
      renderInput={(params) => <TextField {...params} fullWidth variant='standard' size='small' placeholder='Search' inputProps={{ ...params.inputProps, sx: { '&::placeholder': { opacity: .8 } } }} sx={{ ...formTextFieldSx, '&.MuiFormControl-root': { backgroundColor: 'transparent' }, '& ::placeholder': { opacity: .8 } }} />}
      renderOption={(props, club) => (
        <li {...props}>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 500 }}>
            {club.shortName}
          </Typography>
        </li>
      )}
      componentsProps={{
        popper: {
          sx: {
            pt: 1
          }
        }
      }}
    />
  )
}

const TransferSimulator = (props) => {
  
  const initialSimulationParameters = {
    contractDuration: 3,
    salaryCurrent: 0,
    transferPrice: 0,
    commissionSeller: 0,
    commissionBuyer: 0,
    performance: 1,
    intensity: 0.75,
    percSalaryPaid: 100
  }

  const emptyMarketIndicatorsFields = [
    { field: 'playerSalaryNet', name: 'currentNetSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'playerNewSalaryNet', name: 'newNetSalary', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'playerSalaryNetDeviation', name: 'netSalaryDeviation', value: '-', valueRenderer: currencyUomRenderer },
    { field: 'similarPlayersSalaryNetAvg', name: 'similarPlayersSalaryNetAvg', value: '-', valueRenderer: currencyUomWithPercRenderer(0) },
    { field: 'similarPlayersSalaryNetMax', name: 'similarPlayersSalaryNetMax', value: '-', valueRenderer: currencyUomWithPercRenderer(0) },
    { field: 'acquiringClubSalaryNetAvg', name: 'acquiringClubSalaryNetAvg', value: '-', valueRenderer: currencyUomWithPercRenderer(0) },
    { field: 'acquiringClubSalaryNetMax', name: 'acquiringClubSalaryNetMax', value: '-', valueRenderer: currencyUomWithPercRenderer(0) },
  ]

  const emptySimulationResultFields = {
    buy: [
      { field: 'maxtr', name: 'maxPayedFee', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'transferPrice', name: 'purchasePrice', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'costs', name: 'salaryGross', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'otherCosts', name: 'otherExpenses', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'totalCosts', name: 'totalInvestment', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'totalCostsLoan', name: 'totalInvestmentLoan', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'expectedBenefits', name: 'expectedIncome', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'expectedBenefitsLoan', name: 'expectedBenefitsLoan', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'benefits', name: 'benefits', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'residualValue', name: 'residualValue', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'netPresentValue', name: 'netProfit', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'netPresentValueRenew', name: 'netPresentValueRenew', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'netPresentValueLoan', name: 'netPresentValueRenewLoan', value: '-', valueRenderer: currencyUomRenderer }
    ],
    sell: [
      { field: 'netCollection', name: 'netCashIn', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'savedCosts', name: 'savedGrossSalary', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'netCash', name: 'netIncome', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'markToMarket', name: 'markToMarket', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'residualBenefit', name: 'expectedIncome', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'ownerResidualValue', name: 'residualValue', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'residualValue', name: 'residualValue', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'residualCosts', name: 'residualCosts', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'residualCostsLoan', name: 'residualCostsLoan', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'potentialPrice', name: 'potentialPrice', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'costs', name: 'salaryGross', value: '-', valueRenderer: currencyUomRenderer },
      { field: 'netProfit', name: 'netProfit', value: '-', valueRenderer: currencyUomRenderer },
    ]
  }

  const userToken = useSelector(state => state.user.token)
  const statistics = useSelector(state => state.statistics)
  const dispatch = useDispatch()

  const [isMarketIndicatorsPending, startMarketIndicatorsTransition] = useTransition()
  const [isSimulationResultPending, startSimulationResultTransition] = useTransition()

  const [simulationRunning, setSimulationRunning] = useState(false)
  const [simulationParameters, setSimulationParameters] = useState(() => initialSimulationParameters)
  const [destinationClub, setDestinationClub] = useState()
  const [validationResult, setValidationResult] = useState({})
  const [marketIndicatorsFields, setMarketIndicatorsFields] = useState(emptyMarketIndicatorsFields)
  const [simulationResult, setSimulationResult] = useState()
  const [simulationResultFields, setSimulationResultFields] = useState(emptySimulationResultFields)
  const [simulationType, setSimulationType] = useState('transfer')
  const [playerOptions, setPlayerOptions] = useState([]);

  
  useEffect(() => {
    if (props.player) {
     
      setSimulationParameters(prev => {
       
        const newParameters = {
          contractDuration: props.player.remainingYearsOfContract === 1 ? props.player.remainingYearsOfContract : (props.player.remainingYearsOfContract - 1),
          salaryCurrent: props.player.salaryOptimal,
          transferPrice: props.player.expectedPrice,
        };
       
        // Only update if the new parameters are different from the current state
        if (
          newParameters.contractDuration !== prev.contractDuration ||
          newParameters.salaryCurrent !== prev.salaryCurrent ||
          newParameters.transferPrice !== prev.transferPrice
        ) {
          return { ...prev, ...newParameters };
        }
  
        return prev; // No need to update
      });

      if (!statistics.leagues[props.player.currentLeagueId]) {
        statisticsService.getLeagueStats(userToken, dispatch, props.player.currentLeagueId)
          .then(res => {
            if (res.status === 200) {
              dispatch(addLeagueStatistics(res.data.data))
            }
          })
      }
      if (!statistics.clusters[props.player.currentClubCluster]) {
        statisticsService.getClusterStatsById(userToken, dispatch, props.player.currentClubCluster)
          .then(res => {
            if (res.status === 200) {
              dispatch(addClusterStatistics(res.data.data))
            }
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, props.player])
 
  useEffect(() => {
    if (props.player) {
      startMarketIndicatorsTransition(() => {
        let newMarketIndicatorsFields = [...marketIndicatorsFields]

        let salaryNetDeviation = simulationParameters.salaryCurrent - newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'playerSalaryNet')].value
        // calculateDeviation(props.player?.salaryNet, simulationParameters.salaryCurrent)

        newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'playerSalaryNet')].value = props.player.salaryNet
        newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'playerNewSalaryNet')].value = simulationParameters.salaryCurrent
        newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'playerSalaryNetDeviation')].value = salaryNetDeviation

        setMarketIndicatorsFields(newMarketIndicatorsFields)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.player, simulationParameters.salaryCurrent])

  useEffect(() => {
    let newMarketIndicatorsFields = [...marketIndicatorsFields]

    if (destinationClub) {
      startMarketIndicatorsTransition(() => {
        transitionWrapper(statisticsService.getClubStats(userToken, dispatch, destinationClub.clubId),
          res => {
            if (res.status === 200) {
              newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetAvg')].value = res.data.data.avgSalaryNet
              newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetAvg')].valueRenderer = currencyUomWithPercRenderer(calculateDeviation(res.data.data.avgSalaryNet, simulationParameters.salaryCurrent))
              newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetMax')].value = res.data.data.maxSalaryNet
              newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetMax')].valueRenderer = currencyUomWithPercRenderer(calculateDeviation(res.data.data.maxSalaryNet, simulationParameters.salaryCurrent))
              setMarketIndicatorsFields(newMarketIndicatorsFields)
            }
          })
        let newSimulationResultFields = { ...simulationResultFields }
        newSimulationResultFields.buy[simulationResultFields.buy.findIndex(f => f.field === 'maxtr')].value = destinationClub.maxtr

        setSimulationResultFields(newSimulationResultFields)
      })
    } else {
      newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetAvg')].value = '-'
      newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetAvg')].valueRenderer = currencyUomWithPercRenderer(0)
      newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetMax')].value = '-'
      newMarketIndicatorsFields[marketIndicatorsFields.findIndex(f => f.field === 'acquiringClubSalaryNetMax')].valueRenderer = currencyUomWithPercRenderer(0)
      setMarketIndicatorsFields(newMarketIndicatorsFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, destinationClub, simulationParameters.salaryCurrent])

  useEffect(() => {
    if (props.player) {
      startMarketIndicatorsTransition(() => {
        let idx = marketIndicatorsFields.findIndex(f => f.field === 'similarPlayersSalaryNetAvg')
        if (idx !== -1) {
          let newMarketIndicatorsField = [...marketIndicatorsFields]
          newMarketIndicatorsField[idx].value = Math.round(props.similarPlayersSalaryNet.avg * 100) / 100
          newMarketIndicatorsField[idx].valueRenderer = currencyUomWithPercRenderer(calculateDeviation(props.similarPlayersSalaryNet.avg, simulationParameters.salaryCurrent))
          setMarketIndicatorsFields(newMarketIndicatorsField)
        }

        idx = marketIndicatorsFields.findIndex(f => f.field === 'similarPlayersSalaryNetMax')
        if (idx !== -1) {
          let newMarketIndicatorsField = [...marketIndicatorsFields]
          newMarketIndicatorsField[idx].value = Math.round(props.similarPlayersSalaryNet.max * 100) / 100
          newMarketIndicatorsField[idx].valueRenderer = currencyUomWithPercRenderer(calculateDeviation(props.similarPlayersSalaryNet.max, simulationParameters.salaryCurrent))
          setMarketIndicatorsFields(newMarketIndicatorsField)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.player, props.similarPlayersSalaryNet, simulationParameters.salaryCurrent])

  useEffect(() => {
    if (simulationResult) {
      startSimulationResultTransition(() => {
        let tmpBuySimulationResult = []
        emptySimulationResultFields.buy.forEach((row) => {
          if (row.field === 'maxtr') {
            tmpBuySimulationResult.push(simulationResultFields.buy.find(f => f.field === 'maxtr'))
          } else {
            tmpBuySimulationResult.push({ field: row.field, name: row.name, value: simulationResult.buy[row.field], valueRenderer: row.valueRenderer })
          }
        })
        let tmpSellSimulationResult = []
        emptySimulationResultFields.sell.forEach((row) => { 
          tmpSellSimulationResult.push({ field: row.field, name: row.name, value: simulationResult.sell[row.field], valueRenderer: row.valueRenderer })
        })
        setSimulationResultFields({
          buy: tmpBuySimulationResult,
          sell: tmpSellSimulationResult
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationResult])

  const calculateDeviation = (originalValue, finalValue) => {
    return Math.round((originalValue - finalValue) / originalValue * 100 * 100) / 100
  }

  const validateParameters = () => {
    let isFormValid = true
    let validationResult = {}
    if(simulationType === 'transfer' || simulationType === 'loan') {
      if (!destinationClub) {
        isFormValid = false
        validationResult.destinationClubId = 'mandatoryFieldError'
      }
    }
    if (simulationParameters.contractDuration === '') {
      isFormValid = false
      validationResult.contractDuration = 'mandatoryFieldError'
    }
    if (simulationParameters.salaryCurrent === '') {
      isFormValid = false
      validationResult.salaryCurrent = 'mandatoryFieldError'
    }
    if (simulationParameters.transferPrice === '') {
      isFormValid = false
      validationResult.transferPrice = 'mandatoryFieldError'
    }
    if (simulationParameters.performance === '' || simulationParameters.performance === 0) {
      isFormValid = false
      validationResult.performance = 'mandatoryFieldError'
    }
    if (simulationParameters.intensity === '' || simulationParameters.intensity === 0) {
      isFormValid = false
      validationResult.intensity = 'mandatoryFieldError'
    }
    if (simulationParameters.commissionSeller === '' && simulationParameters.commissionBuyer === '') {
      isFormValid = false
      validationResult.commissionSeller = 'mandatoryFieldError'
      validationResult.commissionBuyer = 'mandatoryFieldError'
    }
    setValidationResult(validationResult)
    return isFormValid
  }

  const handleRunSimulationClicked = () => {
  
    if (validateParameters() && props.player && props.player.playerId) {
      setSimulationRunning(true)
      
      let simulationData = [{
        ...simulationParameters,
        playerId: props.player.playerId,
        destinationClubId:simulationType === 'transfer' || 'loan' ? destinationClub.clubId : props.player.currentClubId,
        transferPrice: simulationType === 'transfer' ? simulationParameters.transferPrice : simulationType === 'loan' && simulationParameters.transferPrice === props?.player.expectedPrice ? 0 : simulationParameters.transferPrice,
        percSalaryPaid: simulationParameters.percSalaryPaid,
      }] 
   
      playersService.simulateTransfer(userToken, dispatch, JSON.stringify(simulationData))
        .then(res => {
          setSimulationRunning(false)
          if (res.status === 200) {
            setSimulationResult(res.data.data[0])
          }
        })
        .catch(err => {
          setSimulationRunning(false)
          console.error(err)
        })
    }
  }

  const simulatorType = (type) => {
    setDestinationClub({
      clubId: props.player.currentClubId,
      name: props.player.currentClubName,
      shortName: props.player.currentClubShortName
    })
    setSimulationResultFields(emptySimulationResultFields)
    setSimulationType(type)
  }


  useEffect(() => {
    if(props.player) {
      let active = true

    const potentialPriceSort = {
      field: 'potentialPrice',
      sort: 'desc'
    }
    
    const timeout = setTimeout(() => {
      playersService.getPlayers(userToken, dispatch, PAGE_ALL, { clubId: destinationClub?.clubId }, potentialPriceSort)
        .then(res => {
          if (active && res.status === 200) {
            setPlayerOptions(res.data.data)
          }
        })
        .catch(err => console.error(err))
    }, 250)
    return () => {
      active = false
      clearTimeout(timeout)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [destinationClub])

  const clubYearlyMaxSalaryGross = playerOptions.reduce((max, player) => Math.max(max, player.salaryGross), -Infinity);
  const totalSalary = playerOptions.reduce((sum, player) => sum + player.salaryGross, 0);
  const clubYearlyAvgSalaryGross = totalSalary / playerOptions.length;

  const customRendererMaxTr = currencyUomWithPercRenderer(calculateDeviation(destinationClub?.maxtr, simulationResultFields.buy.find(obj => obj.field === 'transferPrice')?.value));
  const customRendererSalary = currencyUomWithPercRenderer(calculateDeviation(clubYearlyMaxSalaryGross, (simulationResultFields.buy.find(obj => obj.field === 'costs')?.value / simulationParameters?.contractDuration)));
  const customRendererYearlySalary = currencyUomWithPercRenderer(calculateDeviation((simulationResultFields.buy.find(obj => obj.field === 'costs')?.value / simulationParameters?.contractDuration), props?.player?.salaryGross));
  const customRendererAvgSalary = currencyUomWithPercRenderer(calculateDeviation(clubYearlyAvgSalaryGross, (simulationResultFields.buy.find(obj => obj.field === 'costs')?.value / simulationParameters?.contractDuration)));

  const ReleasingTableContainer = () => {
  
    return (
      <TableContainer>
        <Table size='small' sx={{ tableLayout: 'fixed' }}>
          <TableBody>
          
            {simulationResultFields.sell.map((field, idx) => (
                simulationType === 'loan'  ?
                field.field === 'residualBenefit' || field.field === 'ownerResidualValue' || field.field === 'residualCostsLoan' ? 
                  <TableRow key={idx} sx={{ height: 45 }}>
                  <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title={<TranslatedContent code={field.name} description />}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          <TranslatedContent code={field.name === 'residualCosts' ? 'salaryGross' : field.name === 'ownerResidualValue' ? 'expectedIncome' : field.name} />
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                    {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value}
                  </TableCell>
                </TableRow>
                :
                  ""
              : 


              simulationType === 'renew'  ?
                field.field === 'savedCosts' || field.field === 'netCash' || field.field === 'residualValue' ? 
                  <TableRow key={idx} sx={{ height: 45 }}>
                  <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title={<TranslatedContent code={field.field === 'savedCosts' ? 'salaryGrossSimulatorRenew' : field.field === 'netCash' ? 'expectedIncomeSimulatorRenew' : field.name} description />}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          <TranslatedContent code={field.field === 'savedCosts' ? 'salaryGrossSimulatorRenew' : field.field === 'netCash' ? 'expectedIncomeSimulatorRenew' : field.name} />
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                    {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value}
                  </TableCell>
                </TableRow>
                :
                  ""


              :
              
              field.field !== 'costs' && field.field !== 'residualCosts' && field.field !== 'residualCostsLoan' && field.field !== 'residualBenefit' && field.field !== 'potentialPrice' && field.field !== 'ownerResidualValue'
              ?
                <TableRow key={idx} sx={{ height: 45 }}>
                  <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title={<TranslatedContent code={field.name} description />}>
                        <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          <TranslatedContent code={field.name === 'netIncome' ? 'transferNetIncome' : field.name} />
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                    {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value}
                  </TableCell>
                </TableRow>
              :
              ""
              
            ))}
            {simulationType === 'loan'  ?
              <TableRow key={332} sx={{ height: 45 }}>
                <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                  <Box sx={{ display: 'flex' }}>
                    <Tooltip title={<TranslatedContent code="playerExpectedPrice" description />}>
                      <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <TranslatedContent code="loanExpectedPrice" />
                      </Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                  {currencyUomRenderer({value: props.player?.expectedPrice})}
                </TableCell>
              </TableRow>
              :
                ""
                }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' }, mb: 2, flexWrap: "wrap"}}>
        <Typography sx={{ color: '#A1CBC2', fontSize: '2rem', fontWeight: 500, textTransform: 'uppercase' }}>
          <TranslatedContent code='transferSimulator' defaultValue='Transfer Simulator' />
        </Typography>
        <Box xs={{
      display: 'flex',
      flexDirection: 'column', // Stack buttons vertically on smaller screens
      alignItems: 'center',
      width: '100%', // Ensure buttons take full width on smaller screens
      marginTop: { xs: 2, sm: 0 }, // Adjust top margin for spacing
    }}>
            <Button onClick={() => simulatorType("transfer")} id='valueBtn' variant='stdTab' className={simulationType === 'transfer' ? "stdActive" : ""} sx={{ mx: 2 }}>
              <TranslatedContent code='Transfer' defaultValue='Transfer' />
            </Button>
            <Button onClick={() => simulatorType("renew")} id='valueBtn' variant='stdTab' className={simulationType === 'renew' ? "stdActive" : ""} sx={{ mx: 2 }}>
              <TranslatedContent code='Renew' defaultValue='Renew' />
            </Button>
            <Button onClick={() => simulatorType("loan")} id='valueBtn' variant='stdTab' className={simulationType === 'loan' ? "stdActive" : ""} sx={{ mx: 2 }}>
              <TranslatedContent code='Loan' defaultValue='Loan' />
            </Button>
            <Button endIcon={<KeyboardArrowRightIcon />}
              onClick={handleRunSimulationClicked} 
              disabled={simulationRunning}
              variant='stdSim'
              className={simulationType === 'renew' ? "stdActive" : ""} 
              sx={{ mx: 2, border: '1px solid #F4D35E', color: '#F4D35E', whiteSpace: 'nowrap', '&:hover': { backgroundColor: '#F4D35E', color: '#15222B' }}}
            >
              <TranslatedContent code='runSimulation' defaultValue='Run Simulation' />
            </Button>
          {/* <Button
            endIcon={<KeyboardArrowRightIcon />}
            onClick={handleRunSimulationClicked}
            disabled={simulationRunning}
            variant='stdTab'
            sx={{ color: '#F4D35E', borderRadius: 25, border: '1px solid #F4D35E', height: 30, ml: { xs: 0, sm: 2 }, mr: 2, my: { xs: 1, sm: 0 }, px: 2, '&:hover': { backgroundColor: '#F4D35E', color: '#15222B' }, '&.Mui-disabled': { color: '#D3D3D3', borderColor: '#707070', backgroundColor: 'transparent' } }}>
            <TranslatedContent code='runSimulation' defaultValue='Run Simulation' />
          </Button> */}
          {simulationRunning && <CircularProgress size={30} sx={{ color: '#F4D35E' }} />}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xxl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              <TranslatedContent code='transferParameters' defaultValue='Transfer Parameters' />
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#04A8A3', height: 2, width: '100%', mt: 1, mb: 2 }} />
          {simulationType === 'transfer' ? (
            <TransferSimulatorForm
              values={simulationParameters}
              onChange={setSimulationParameters}
              validationResult={validationResult}
              setDestinationClub={setDestinationClub}
            />
          ) : simulationType === 'loan' ? (
            <LoanSimulatorForm
              values={simulationParameters}
              onChange={setSimulationParameters}
              validationResult={validationResult}
              setDestinationClub={setDestinationClub}
              player={props.player}
            />
          ) : (
            <RenewSimulatorForm
              simulationType={simulationType}
              values={simulationParameters}
              onChange={setSimulationParameters}
              validationResult={validationResult}
              setDestinationClub={setDestinationClub}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} xxl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              <TranslatedContent code='player' defaultValue='Player' />
            </Typography>
            <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              {props.player?.name}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#F4D35E', height: 2, width: '100%', mt: 1, mb: 2 }} />
          {isMarketIndicatorsPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <TableContainer>
              <Table size='small' sx={{ tableLayout: 'fixed'}}>
                <TableBody>
                  {marketIndicatorsFields.map((field, idx) => (
                    <TableRow key={idx} sx={{ height: 45 }}>
                      <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title={<TranslatedContent code={field.name} description />}>
                            <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                              {
                                simulationType === 'renew' ? 
                                field.name === 'acquiringClubSalaryNetAvg' ? 
                                  <TranslatedContent code={'clubSalaryNetAvg'} /> :
                                field.name === 'acquiringClubSalaryNetMax' ? 
                                  <TranslatedContent code={'clubSalaryNetAvgMax'} />
                                  :
                                  <TranslatedContent code={field.name} /> :
                                  simulationType === 'loan' ? 
                                  field.name === 'acquiringClubSalaryNetAvg' ? 
                                    <TranslatedContent code={'loaningInSalaryNetAvg'} /> :
                                  field.name === 'acquiringClubSalaryNetMax' ? 
                                    <TranslatedContent code={'loaningInSalaryNetAvgMax'} />
                                    :
                                    <TranslatedContent code={field.name} />
                                :
                                <TranslatedContent code={field.name} />
                              }
                            </Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                        {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}
        </Grid>
        {simulationType === 'transfer' ? 
        (
          <Grid item xs={12} md={6} xxl={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
                <TranslatedContent code='releasingClub' defaultValue={'Releasing Club'} />
              </Typography>
              <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
                {props.player?.masterClubName}
              </Typography>
            </Box>
            <Divider sx={{ backgroundColor: '#F4D35E', height: 2, width: '100%', mt: 1, mb: 2 }} />
            {isSimulationResultPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
              <ReleasingTableContainer/>
            }
          </Grid>
        
        ) : simulationType === 'loan' ? (
          <Grid item xs={12} md={6} xxl={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
                <TranslatedContent code='loaningOutClub' defaultValue={'Loaning Out Club'} />
              </Typography>
              <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
                {props.player?.masterClubName}
              </Typography>
            </Box>
            <Divider sx={{ backgroundColor: '#F4D35E', height: 2, width: '100%', mt: 1, mb: 2 }} />
            {isSimulationResultPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
              <ReleasingTableContainer/>
            }
          </Grid>
        )
        : 
        <Grid item xs={12} md={6} xxl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              <TranslatedContent code='oldContract' defaultValue={'Old Contract'} />
            </Typography>
            {/* <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              {props.player?.masterClubName}
            </Typography> */}
          </Box>
          <Divider sx={{ backgroundColor: '#F4D35E', height: 2, width: '100%', mt: 1, mb: 2 }} />
          {isSimulationResultPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <ReleasingTableContainer/>
          }
        </Grid>
        
        }
        
        <Grid item xs={12} md={6} xxl={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minHeight: 25 }}>
            <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              <TranslatedContent code={simulationType === 'transfer' ? 'acquiringClub' : simulationType === 'loan' ? 'Borrowing club' : 'renewFor'} defaultValue={simulationType === 'transfer' ? 'Acquiring Club' : simulationType === 'loan' ? 'Loaning In club' : 'New Contract'} />
            </Typography>
            <Typography sx={{ color: '#F4D35E', fontSize: '1.4rem', fontWeight: 500, textTransform: 'uppercase' }}>
              {destinationClub?.shortName}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#F4D35E', height: 2, width: '100%', mt: 1, mb: 2 }} />
          {isSimulationResultPending ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 200 }}><CircularProgress /></Box> :
            <TableContainer>
              <Table size='small' sx={{ tableLayout: 'fixed' }}>
                <TableBody>
                 
                  {simulationResultFields.buy.map((field, idx) => (
                    simulationType === 'renew'  ?
                    field.field === 'maxtr' || field.field === 'benefits' || field.field === 'transferPrice' || field.field === 'otherCosts' || field.field === 'totalCosts' || field.field === 'netPresentValueLoan' || field.field === 'netPresentValue' || field.field === 'expectedBenefitsLoan' || field.field === 'totalCostsLoan' ? 
                      ""
                    :
                      <TableRow key={idx} sx={{ height: 45, width: '62%' }}>
                        <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title={<TranslatedContent code={field.name} description />}>
                              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                <TranslatedContent code={field.name} />
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: '17%' }}>
                        {field.field === 'transferPrice' && (
                                <Tooltip title={
                                   simulationResultFields.buy.map((field, idx) => (
                                    field.field === 'maxtr' ?
                                      <Fragment>
                                        <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                          <Box sx={{ display: 'flex' }}>
                                            <Tooltip title={<TranslatedContent code={field.name} description />}>
                                              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                <TranslatedContent code={field.name} />
                                              </Typography>
                                            </Tooltip>
                                          </Box>
                                        </TableCell>
                                        <TableCell sx={{ color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                          {customRendererMaxTr({value: field.value})}
                                        </TableCell>
                                      </Fragment>
                                    :
                                    ""
                                  ))
                                }>
                                  <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    <Tooltip arrow>
                                      <div>
                                        <Typography>
                                          <IconButton style={{ fontSize: 16 }}> 
                                            <InfoIcon style={{ fontSize: 22, color: '#fff'}} />
                                          </IconButton>
                                        </Typography>
                                      </div>
                                    </Tooltip>
                                  </Typography>
                                </Tooltip>
                            )}
                            {field.field === 'costs' && (
                                <Tooltip title={
                                     <Fragment>
                                      <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code="salaryGross" description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="yearlySalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem',textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererYearlySalary({ value: field.value / simulationParameters?.contractDuration })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="maxYearlyGrossSalary" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E',fontSize: '1.1rem',textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererSalary({ value: clubYearlyMaxSalaryGross })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="avgSalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem',textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererAvgSalary({ value: Math.round(clubYearlyAvgSalaryGross, 2) })}
                                       </TableCell>
                                       </TableRow>
                                     </Fragment>
                               }>
                                 <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                   <Tooltip arrow>
                                     <div>
                                       <Typography>
                                         <IconButton style={{ fontSize: 16 }}> 
                                           <InfoIcon style={{ fontSize: 22, color: '#fff'}} />
                                         </IconButton>
                                       </Typography>
                                     </div>
                                   </Tooltip>
                                 </Typography>
                               </Tooltip>
                            )}
                        </TableCell>
                        <TableCell sx={{ width: '20%', color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                          {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value}
                        </TableCell>
                      </TableRow>
                    : simulationType === 'loan'  ?
                      field.field === 'residualValue' || field.field === 'maxtr' || field.field === 'netPresentValue' || field.field === 'otherCosts' || field.field === 'netPresentValueRenew' || field.field === 'expectedBenefits' || field.field === 'expectedBenefitsLoan' || field.field === 'totalCosts' ? 
                          "" 
                        :
                        <TableRow key={idx} sx={{ height: 45 }}>
                        <TableCell sx={{ p: { xs: 0, md: 1 }, width: '62%' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title={<TranslatedContent code={field.name === 'purchasePrice' ? 'loanFee' : field.name === 'benefits' ? 'expectedIncome' : field.name === 'totalInvestmentLoan' ? 'totalInvestmentLoan' : field.name} description />}>
                              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                <TranslatedContent code={field.name === 'purchasePrice' ? 'loanFee' : field.name === 'benefits' ? 'expectedIncome' : field.name === 'totalInvestmentLoan' ? 'totalInvestmentLoan' : field.name} />
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: '17%' }}>
                        
                            {field.field === 'costs' && (
                                <Tooltip title={
                                     <Fragment>
                                      <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code="yearlySalaryGross" description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="yearlySalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem',textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {currencyUomRenderer({ value: field.value / simulationParameters?.contractDuration })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="maxYearlyGrossSalary" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererSalary({ value: clubYearlyMaxSalaryGross })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="avgSalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererAvgSalary({ value: Math.round(clubYearlyAvgSalaryGross, 2) })}
                                       </TableCell>
                                       </TableRow>
                                     </Fragment>
                               }>
                                 <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                   <Tooltip arrow>
                                     <div>
                                       <Typography>
                                         <IconButton style={{ fontSize: 16 }}> 
                                           <InfoIcon style={{ fontSize: 22, color: '#fff'}} />
                                         </IconButton>
                                       </Typography>
                                     </div>
                                   </Tooltip>
                                 </Typography>
                               </Tooltip>
                            )}
                        </TableCell> 
                        <TableCell sx={{ width: '20%', color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                          {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value} 
                        </TableCell>
                           
                      </TableRow>
                      :
                      field.field === 'maxtr' || field.field === 'netPresentValueLoan' || field.field === 'netPresentValueRenew' || field.field === 'expectedBenefitsLoan' || field.field === 'benefits' || field.field === 'totalCostsLoan' ? 
                        ""
                      :
                      <TableRow key={idx} sx={{ height: 45 }}>
                        <TableCell sx={{ p: { xs: 0, md: 1 }, width: '62%' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title={<TranslatedContent code={field.name} description />}>
                              <Typography sx={{ color: '#FFFFFF', fontSize: '1.4rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                <TranslatedContent code={field.name} />
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ width: '17%' }}>
                        {field.field === 'transferPrice' && (
                                ''
                            )}
                            {field.field === 'costs' && (
                                <Tooltip  title={
                                     <Fragment>
                                      <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code="salaryGross" description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="yearlySalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E',fontSize: '1.1rem', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {currencyUomRenderer({ value: field.value / simulationParameters?.contractDuration })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="maxYearlyGrossSalary" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E', fontSize: '1.1rem', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererSalary({ value: clubYearlyMaxSalaryGross })}
                                       </TableCell>
                                       </TableRow>
                                       <TableRow>
                                       <TableCell sx={{ p: { xs: 0, md: 1 } }}>
                                         <Box sx={{ display: 'flex' }}>
                                           <Tooltip title={<TranslatedContent code={field.name} description />}>
                                             <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                               <TranslatedContent code="avgSalaryGross" />
                                             </Typography>
                                           </Tooltip>
                                         </Box>
                                       </TableCell>
                                       <TableCell sx={{ color: '#F4D35E',fontSize: '1.1rem', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                                         {customRendererAvgSalary({ value: Math.round(clubYearlyAvgSalaryGross, 2) })}
                                       </TableCell>
                                       </TableRow>
                                     </Fragment>
                               }>
                                 <Typography sx={{ color: '#FFFFFF', fontSize: '1.1rem', fontWeight: 600, textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                   <Tooltip arrow>
                                     <div>
                                       <Typography>
                                         <IconButton> 
                                           <InfoIcon style={{ fontSize: 22, color: '#fff'}} />
                                         </IconButton>
                                       </Typography>
                                     </div>
                                   </Tooltip>
                                 </Typography>
                               </Tooltip>
                            )}
                        </TableCell> 
                        <TableCell sx={{ width: '20%', color: '#F4D35E', textAlign: 'right', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', p: { xs: 0, md: 1 } }}>
                          {field.valueRenderer && field.value !== '-' ? field.valueRenderer({ value: field.value }) : field.value} 
                        </TableCell>
                           
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}
        </Grid>
      </Grid>
    </Fragment >
  )
}

export default TransferSimulator