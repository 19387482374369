import TuneIcon from '@mui/icons-material/Tune'
import { Box, Button, Divider, IconButton, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import filtersService from '../../services/filtersService'
import statisticsService from '../../services/statisticsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import {
  monthsToYearsMonthsUomRenderer,
  currencyHeaderRenderer,
  decimalNumberRenderer,
  decimalValueWithPercRenderer,
  integerValueWithPercRenderer,
  tierGridRenderer,
  translatedHeaderRenderer,
  yearsToYearsMonthsUomRenderer,
  percentageFormatter,
  decimalNumberGridRenderer
} from '../data/formatters/dataFormatters'
import StdDataGrid from '../data/stdDataGrid'
import '../data/stdDataGrid.css'
import StdRowCount from '../data/stdRowCount'
import StdNameSearchField from '../form/stdNameSearchField'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'

const TabSelect = (props) => (
  <StdSelect
    value={props.tabState}
    onChange={(e) => props.setTabState(e.target.value)}
    renderValue={(value) => <TranslatedContent code={value} />}
    sx={{
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontSize: '1.2rem',
      height: 25,
      width: 150,
      border: '1px solid #04A8A3',
      borderRadius: '25px',
      '&:hover': {
        color: '#04A8A3'
      }
    }}>
    <MenuItem id='general' key='general' value='general'>
      <TranslatedContent code='general' defaultValue='General' />
    </MenuItem>
    
    <MenuItem id='value' key='value' value='value'>
      <TranslatedContent code='value' defaultValue='Value' />
    </MenuItem>
    <MenuItem id='salary' key='salary' value='salary'>
      <TranslatedContent code='salary' defaultValue='Salary' />
    </MenuItem>
  </StdSelect>
)


const StdLeaguesTable = forwardRef((props, ref) => {

  const clubsTotal = useSelector(state => state.leagues.total)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const [numOfClubs, setNumOfClubs] = useState(clubsTotal)
  const [numOfClubClusters, setNumOfClubClusters] = useState(clubClusters.length)

  const leagueNameRenderer = ({ cell, row }) => (
    <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault()
        navigate('/leagues/' + row.original.leagueId)
      }}>
      {cell.getValue()}&nbsp;({row.original.country})
    </Typography>
  )

  const foreignPlayersRenderer = ({ cell, row }) => integerValueWithPercRenderer(row.original.shareOfForeignPlayers)({ value: cell.getValue() })

  const U23PlayersRenderer = ({ cell, row }) => integerValueWithPercRenderer(row.original.shareOfU23Players)({ value: cell.getValue() })

  const tierDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percSalaryNetClusterDeviation)({ value: cell.getValue() })

  const leagueDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percLeagueSalaryNetDeviation)({ value: cell.getValue() })

  const salaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.salaryPercDeviation)({ value: cell.getValue() })
console.log(props);
  const countColumn = {
    header: 'count',
    maxSize: 30,
    enableSorting: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', className: 'StdCountCell', sx: { color: '#FFFFFF', backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdCountCell' },
    Header: () => <></>,
    Cell: ({ cell }) => <StdRowCount cell={cell} rowId='clubId' />
  }

  const nameColumn = {
    accessorKey: 'name',
    header: 'name',
    enableSorting: true,
    enableColumnActions: true,
    enableColumnOrdering: true,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', sx: { backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdNameCell', sx: { borderRightWidth: 4 } },
    // Header: () => (
    //   <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
    //     <StdNameSearchField ref={ref} className='StdClubsNameSearchField'  onSearch={props.filterByName}/>
    //   </Box>
    // ),
    Header: translatedHeaderRenderer,
    muiTableHeadCellProps: { align: 'center', className: 'StdHeader' },
    muiTableBodyCellProps: { align: 'left', className: 'StdColumn' },
    Cell: ({ cell }) => (
      <Typography sx={{ color: '#F4D35E', fontSize: '1.5rem', fontWeight: 600, cursor: 'pointer' }}
        onClick={() => navigate('/leagues/' + cell.row.original.leagueId)}>
        {cell.getValue()}
      </Typography>
    )
  }

  const generalColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'League Country', accessorKey: 'country', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'left', className: 'StdColumn' } },
    { header: 'League Total revenues', accessorKey: 'totalRevenues', Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'League Confederation', accessorKey: 'confederation', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'League Member Association', accessorKey: 'memberAssociation', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'League Fifa Ranking', accessorKey: 'fifaRank', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueNumOfClubs', accessorKey: 'numOfClubs', minSize: 140, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueNumOfPlayers', accessorKey: 'numOfPlayers', minSize: 200, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'League Cluster', accessorKey: 'cluster', minSize: 140, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    // { header: 'leagueNumOfForeignPlayers', accessorKey: 'numOfForeignPlayers', minSize: 200, Header: translatedHeaderRenderer, Cell: foreignPlayersRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    // { header: 'leagueNumOfU23Players', accessorKey: 'numOfU23Players', minSize: 200, Header: translatedHeaderRenderer, Cell: U23PlayersRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueAvgAge', accessorKey: 'avgAge', minSize: 200, Header: translatedHeaderRenderer,Cell: ({ cell }) => yearsToYearsMonthsUomRenderer({ value: cell.getValue() }), muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
  ], [numOfClubClusters])

  const playerColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'League Players number', accessorKey: 'numOfPlayers', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueNumOfForeignPlayers', accessorKey: 'numOfForeignPlayers', minSize: 200, Header: translatedHeaderRenderer, Cell: foreignPlayersRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueNumOfU23Players', accessorKey: 'numOfU23Players', minSize: 200, Header: translatedHeaderRenderer, Cell: U23PlayersRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueNumOfU23ForeignPlayers', accessorKey: 'numOfU23ForeignPlayers', minSize: 200, Header: translatedHeaderRenderer, Cell: U23PlayersRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'League average players age', accessorKey: 'avgAge', minSize: 150, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'League average players contract duration', accessorKey: 'avgContractResidualDuration', minSize: 240, Cell: ({ cell }) => monthsToYearsMonthsUomRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } }
  ], [numOfClubClusters])

  const valueColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'League Fair Value', accessorKey: 'potentialPrice', minSize: 200, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueRankByValue', accessorKey: 'rankByValue', minSize: 190, Header: currencyHeaderRenderer, Cell: tierGridRenderer(numOfClubs), muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueAcquisitionCost',accessorKey: 'portfolioCost', minSize: 240, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueRankByAcquisition',accessorKey: 'rankByAcquisition', minSize: 250, Cell: tierGridRenderer(numOfClubs), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueResidualBookValue', accessorKey: 'residualBookValue', minSize: 260, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueResidualValue',accessorKey: 'residualValue', minSize: 250, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
  ], [numOfClubs])

  const salaryColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'salaryGross', accessorKey: 'salaryGross', size: 200, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'rankBySalary', accessorKey: 'rankBySalary', size: 200, Cell: tierGridRenderer(numOfClubs), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'leagueSalaryNet', accessorKey: 'salaryNet', size: 210, Cell: ({ cell }) => decimalNumberRenderer({ value: cell.getValue() }), Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueSalaryFair', accessorKey: 'salaryOptimal', size: 220, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueSalaryAbsDeviation', accessorKey: 'salaryAbsoluteDeviation', size: 190, Cell: salaryDeviationRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
  ], [numOfClubs])

  const kpiColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'leagueRevenueGrowth', accessorKey: 'revenueGrowth', size: 200, Cell: percentageFormatter,Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueRevenueShare', accessorKey: 'revenueShare', size: 200, Cell: percentageFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueNetProfitLoss', accessorKey: 'netProfitLoss', size: 220, Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer,muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leaguePlayerTrading', accessorKey: 'playerTrading', size: 220, Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'leagueFinancialSustainabilityIndex', accessorKey: 'financialSustainabilityIndex', size: 190, Header: translatedHeaderRenderer, Cell: percentageFormatter, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
  ], [numOfClubs])

  const marketColumns = useMemo(() => [
    countColumn,
    nameColumn,
    { header: 'expenditure5yAvg', accessorKey: 'expenditure5yAvg' },
    { header: 'income5yAvg', accessorKey: 'income5yAvg' },
    { header: 'netIncome5yAvg', accessorKey: 'netIncome5yAvg' },
    { header: 'topSale', accessorKey: 'maxTr' },
    { header: 'topPurchase', accessorKey: 'topPurchase' },
    { header: 'expenditureSeason', accessorKey: 'expenditureSeason' },
    { header: 'incomeSeason', accessorKey: 'incomeSeason' },
    { header: 'netIncomeSeason', accessorKey: 'netIncomeSeason' },
    { header: 'startingRoasterValue', accessorKey: 'startingRoasterValue' },
    { header: 'currentRoasterValue', accessorKey: 'potentialPrice' },
    { header: 'appreciationDepreciation', accessorKey: 'appreciationDepreciation' },
    { header: 'pointsSeason', accessorKey: 'pointsSeason' },
    { header: 'costPerPoint', accessorKey: 'costPerPoint' },
    { header: 'valuePerPoint', accessorKey: 'valuePerPoint' }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const userToken = useSelector(state => state.user.token)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [clubs, setClubs] = useState(props.clubs)
  const [columns, setColumns] = useState(generalColumns)
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnOrder, setColumnOrder] = useState(columns.map(c => c.accessorKey))

  const [tabState, setTabState] = useState('general')
  const [filterOpen, setFilterOpen] = useState(false)

  const isBreakpointSm = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    const timeout = setTimeout(() => {
      let headerWrappers = document.getElementsByClassName('Mui-TableHeadCell-Content-Wrapper')
      for (let i = 0; i < headerWrappers.length; i++) {
        headerWrappers.item(i).removeAttribute('title')
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [columns])

  useEffect(() => {
    setColumnOrder(columns.map(c => c.accessorKey))
  }, [columns])

  useEffect(() => {
    setNumOfClubs(clubsTotal)
  }, [clubsTotal])

  useEffect(() => {
    setNumOfClubClusters(clubClusters.length)
  }, [clubClusters])

  useEffect(() => {
    if (!props.disableFilters) {
      setFilterOpen(props.filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterOpen])

  useEffect(() => {
    if (!props.disableFilters) {
      props.setFilterOpen(filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOpen])

  useEffect(() => {
    if (isBreakpointSm) {
      setColumnVisibility(prevState => ({ ...prevState, count: true }))
    } else {
      setColumnVisibility(prevState => ({ ...prevState, count: false }))
    }
  }, [isBreakpointSm])

  useEffect(() => {
    if (clubsTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    setClubs(props.clubs)
  }, [props.clubs])

  useEffect(() => {
    let newColumns = []
    switch (tabState) {
      case 'general':
        newColumns = generalColumns
        break
      case 'player':
        newColumns = playerColumns
        break
      case 'value':
        newColumns = valueColumns
        break
      case 'salary':
        newColumns = salaryColumns
        break
      case 'kpi':
        newColumns = kpiColumns
      break
      case 'market':
        newColumns = marketColumns
        break
      default:
        console.warn('[StdClubsTable] unhandled tab state selected: ' + tabState)
        break
    }
    setColumns(newColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState, numOfClubs, numOfClubClusters])

  const toggleFilterOpen = (e) => {
    e.preventDefault()
    setFilterOpen(prevState => !prevState)
  }

  const handleTabClick = (e) => {
    switch (e.target.id) {
      case 'generalBtn':
        setTabState('general')
        break
      case 'playerBtn':
        setTabState('player')
        break
      case 'playersBtn':
        setTabState('players')
        break
      case 'valueBtn':
        setTabState('value')
        break
      case 'salaryBtn':
        setTabState('salary')
        break
      case 'kpiBtn':
        setTabState('kpi')
        break
      default:
        console.warn('[StdClubsTable] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{
            color: '#FFFFFF',
            fontSize: '3rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            mr: 2
          }}>
            {/* <TranslatedContent code='leagues' defaultValue='Leagues' /> */}
          </Typography>
          {/* <Tooltip title={<TranslatedContent code='leaguesInfo' description />}>
            <InfoIcon fill='#FFFFFF' height='20px' />
          </Tooltip> */}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: { xs: 'none', xl: 'flex' }, flexDirection: 'row' }}>
            <Button id='generalBtn' variant='stdTab' className={tabState === 'general' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='general' defaultValue='General' />
            </Button>
            <Button id='playerBtn' variant='stdTab' className={tabState === 'player' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='' defaultValue='Players' />
            </Button>
            <Button id='valueBtn' variant='stdTab' className={tabState === 'value' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='value' defaultValue='Value' />
            </Button>
            <Button id='salaryBtn' variant='stdTab' className={tabState === 'salary' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='salary' defaultValue='Salary' />
            </Button>
            <Button id='kpiBtn' variant='stdTab' className={tabState === 'kpi' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='kpi' defaultValue='Kpi' />
            </Button>
            {/* <Button id='marketBtn' variant='stdTab' className={tabState === 'market' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
            <TranslatedContent code='market' defaultValue='Market' />
          </Button> */}
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block', xl: 'none' } }}>
            <TabSelect tabState={tabState} setTabState={setTabState} />
          </Box>
          <Box sx={{ display: props.disableFilters ? 'none' : 'block' }}>
            <IconButton onClick={toggleFilterOpen} sx={{ backgroundColor: '#FFFFFF', borderRadius: '50%', p: 1, ml: 3, '&:hover': { backgroundColor: '#FFFFFF' } }}>
              <TuneIcon sx={{ color: '#04A8A3' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#FFFFFF', height: 2, width: '100%', mt: 1, mb: { xs: 2, sm: 4 } }} />
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, width: '100%', justifyContent: 'center', mb: 2 }}>
        <TabSelect tabState={tabState} setTabState={setTabState} />
      </Box>
      <StdDataGrid
        columns={columns}
        data={clubs}
        rowId={(club) => club.clubId}
        rowCount={props.rowCount}
        enablePagination={props.enablePagination}
        manualPagination={props.manualPagination}
        page={props.page}
        pageSize={props.pageSize}
        onPaginationChange={props.onPaginationChange}
        sorting={props.sorting}
        onSortingChange={props.onSortingChange}
        columnVisibility={columnVisibility}
        columnOrder={columnOrder}
        onColumnOrderChange={setColumnOrder}
        enableBottomToolbar={props.enableBottomToolbar} />
    </Box>
  )
})

export default StdLeaguesTable