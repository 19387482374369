import TuneIcon from '@mui/icons-material/Tune'
import { Autocomplete, Box, Button, Divider, IconButton, MenuItem, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { Fragment, forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddCircleIcon } from '../../icons/add_circle.svg'
import { ReactComponent as CancelCircleIcon } from '../../icons/cancel_circle.svg'
import { ReactComponent as CheckCircleIcon } from '../../icons/check_circle.svg'
import { ReactComponent as EditCircleIcon } from '../../icons/edit_circle.svg'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import { ReactComponent as PinCircleIcon } from '../../icons/pin_circle.svg'
import { ReactComponent as RemoveCircleIcon } from '../../icons/remove_circle.svg'
import { PAGE_ALL } from '../../services/commonService'
import filtersService from '../../services/filtersService'
import playersService from '../../services/playersService'
import statisticsService from '../../services/statisticsService'
import { setClubClusters, setClubsTotal } from '../../slices/clubsSlice'
import { setLeagueGroups, setLeaguesTotal } from '../../slices/leaguesSlice'
import { setPlayersTotal } from '../../slices/playersSlice'
import { booleanRenderer, countryGridRenderer, currencyHeaderRenderer, dateGridFormatter, decimalNumberGridRenderer, decimalNumberWithVariationRenderer, decimalValueWithPercRenderer, integerNumberGridRenderer, percNumberGridRenderer, positionGridRenderer, roleGridRenderer, tierGridRenderer, translatedHeaderRenderer,percentageFormatter} from '../data/formatters/dataFormatters'
import StdDataGrid from '../data/stdDataGrid'
import '../data/stdDataGrid.css'
import StdRowCount from '../data/stdRowCount'
import StdNameSearchField from '../form/stdNameSearchField'
import StdSelect from '../form/stdSelect'
import TranslatedContent from '../translations/translatedContent'
import WatchlistPopper from '../watchlists/watchlistPopper'

const TabSelect = (props) => (
  <StdSelect
    value={props.tabState}
    onChange={(e) => props.setTabState(e.target.value)}
    renderValue={(value) => <TranslatedContent code={value} />}
    sx={{
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      fontSize: '1.2rem',
      height: 25,
      width: 150,
      border: '1px solid #04A8A3',
      borderRadius: '25px',
      '&:hover': {
        color: '#04A8A3'
      }
    }}>
    <MenuItem id='general' key='general' value='general'>
      <TranslatedContent code='general' defaultValue='General' />
    </MenuItem>
    <MenuItem id='performance' key='performance' value='performance'>
      <TranslatedContent code='performance' defaultValue='Performance' />
    </MenuItem>
    <MenuItem id='value' key='value' value='value'>
      <TranslatedContent code='value' defaultValue='Value' />
    </MenuItem>
    <MenuItem id='salary' key='salary' value='salary'>
      <TranslatedContent code='salary' defaultValue='Salary' />
    </MenuItem>
    <MenuItem id='kpi' key='kpi' value='kpi'>
      <TranslatedContent code='kpi' defaultValue='Kpi' />
    </MenuItem>
  </StdSelect>
)

export const AddToWatchlistCell = (props) => {

  const personalWatchlists = useSelector(state => state.watchlists.personal)
  const [isInAtLeastOneWatchlist, setIsInAtLeastOneWatchlist] = useState(false)

  useEffect(() => {
    let present = false
    personalWatchlists.every((w) => {
      if (!w.players) return
      present |= Object.keys(w.players).includes(props.cell.row.id)
      return !present
    })
    setIsInAtLeastOneWatchlist(present)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalWatchlists])

  const handleAddToWatchlistButtonClicked = (e, playerId) => {
    e.stopPropagation()
    props.popperRef.current.openWatchlistPopper(playerId, e.currentTarget)
  }

  return (
    <Tooltip title={<TranslatedContent code='addToWatchlist' defaultValue='Add to watchlist' />} placement='top'>
      <IconButton onClick={(e) => handleAddToWatchlistButtonClicked(e, props.cell.row.id)} sx={{ p: 0 }}>
        <AddCircleIcon fill={isInAtLeastOneWatchlist ? '#04A8A3' : '#707070'} width='20px' height='20px' />
      </IconButton>
    </Tooltip>
  )
}

const AddToWatchlistAutocomplete = (props) => {

  const textFieldSx = {
    px: 1,
    '& :before': {
      borderBottom: 0
    },
    '& ::before': {
      borderBottom: 0
    },
    '& .MuiInputBase-root': {
      fontSize: '1.6rem'
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
      borderBottom: 0
    },
    '& :hover:not(.Mui-disabled, .Mui-error)::before': {
      borderBottom: 0
    },
    '& :after': {
      borderBottom: 0
    },
    '& ::after': {
      borderBottom: 0
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):after': {
      borderBottom: 0
    },
    '& :hover:not(.Mui-disabled, .Mui-error)::after': {
      borderBottom: 0
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset'
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: 'rgba(255, 255, 255, .65)'
    }
  }

  const userToken = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  const [playerValue, setPlayerValue] = useState(null)
  const [playerInputValue, setPlayerInputValue] = useState('')
  const [playerOptions, setPlayerOptions] = useState([])

  useEffect(() => {
    let active = true
    if (playerInputValue === '') {
      setPlayerOptions([])
      return undefined
    }
    const potentialPriceSort = {
      field: 'potentialPrice',
      sort: 'desc'
    }
    const timeout = setTimeout(() => {
      playersService.getPlayers(userToken, dispatch, PAGE_ALL, { name: playerInputValue }, potentialPriceSort)
        .then(res => {
          if (active && res.status === 200) {
            setPlayerOptions(res.data.data.filter(p => !Object.keys(props.watchlistPlayers).includes(p.playerId)))
          }
        })
        .catch(err => console.error(err))
    }, 250)
    return () => {
      active = false
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, playerValue, playerInputValue])

  return (
    <Box sx={{ display: 'flex' }}>
      <Autocomplete
        sx={{
          ...props.sx,
          ...textFieldSx
        }}
        freeSolo
        getOptionLabel={(player) => player.name}
        options={playerOptions}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        value={playerValue}
        noOptionsText='No Players'
        onChange={(_event, newValue) => {
          setPlayerOptions([])
          setPlayerValue(newValue)
        }}
        onInputChange={(_event, newInputValue) => {
          setPlayerInputValue(newInputValue)
        }}
        renderInput={(params) => <TextField {...params} fullWidth variant='standard' size='small' placeholder='Search' inputProps={{ ...params.inputProps, sx: { color: '#A1CBC2', '&::placeholder': { color: '#FFFFFF', opacity: .65 } } }} sx={{ '&.MuiFormControl-root': { backgroundColor: 'transparent' }, '& ::placeholder': { opacity: .65 } }} />}
        renderOption={(props, player) => (
          <li {...props}>
            <Typography sx={{ fontSize: '1.6rem', fontWeight: 500 }}>
              {player.name}
            </Typography>
          </li>
        )}
        componentsProps={{
          popper: {
            sx: {
              pt: 1
            }
          }
        }}
      />
      <IconButton disabled={!playerValue || playerValue === ''} sx={{ mx: .5 }}
        onClick={(e) => {
          e.preventDefault()
          props.onAddToWatchlist(playerValue.playerId)
          setPlayerValue()
        }}>
        <AddCircleIcon fill={!playerValue || playerValue === '' ? '#707070' : '#04A8A3'} width='24px' height='24px' />
      </IconButton>
    </Box>
  )
}

const WatchlistPlayerPosition = (props) => {

  const [wPlayerPosition, setWPlayerPosition] = useState(props.params.value)

  useEffect(() => {
    setWPlayerPosition(props.params.value)
  }, [props.params.value])

  return (
    <TextField
      type='number'
      variant='standard'
      size='small'
      value={wPlayerPosition}
      onChange={(e) => setWPlayerPosition(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.onChangePlayerPosition(props.params.id, Number(e.target.value))
        }
      }}
      inputProps={{ maxLength: 2 }}
      InputProps={{
        sx: {
          color: '#15222B',
          backgroundColor: '#FFFFFF',
          fontSize: '1.4rem',
          fontWeight: 500,
          width: 22,
          height: 22,
          outline: 0,
          '&::before': { border: 0 },
          '&::after': { border: 0 },
          '&:hover:not(.Mui-disabled)::before': { borderBottom: 0 },
          'input': { textAlign: 'center' }
        }
      }}
    />)
}

const StdPlayersTable = forwardRef((props, ref) => {

  const popperRef = useRef()

  const playersTotal = useSelector(state => state.players.total)
  const leagueGroups = useSelector(state => state.leagues.leagueGroups)
  const clubClusters = useSelector(state => state.clubs.clubClusters)

  const [numOfPlayers, setNumOfPlayers] = useState(playersTotal)
  const [numOfLeagueGroups, setNumOfLeagueGroups] = useState(leagueGroups.length)

  const potentialPriceVariationRenderer = ({ cell, row }) => decimalNumberWithVariationRenderer(row.original.fairValueVariation)({ value: cell.getValue() })

  const effectivenessVariationRenderer = ({ cell, row }) => decimalNumberWithVariationRenderer(row.original.effectivenessVariation)({ value: cell.getValue() })

  const salaryOptimalVariationRenderer = ({ cell, row }) => decimalNumberWithVariationRenderer(row.original.fairSalaryVariation)({ value: cell.getValue() })

  const expectedPriceVariationRenderer = ({ cell, row }) => decimalNumberWithVariationRenderer(row.original.expectedPriceVariation)({ value: cell.getValue() })

  const salaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.salaryPercDeviation)({ value: cell.getValue() })

  const clubAvgSalaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percClubAvgSalaryDeviation)({ value: cell.getValue() })

  const leagueAvgSalaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percLeagueAvgSalaryDeviation)({ value: cell.getValue() })

  const clubClusterAvgSalaryDeviationRenderer = ({ cell, row }) => decimalValueWithPercRenderer(row.original.percClubClusterAvgSalaryDeviation)({ value: cell.getValue() })

  const masterClubNameRenderer = ({ cell, row }) => (
    <Typography sx={{ fontSize: 'inherit', fontWeight: 'inherit', cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault()
        navigate('/clubs/' + row.original.masterClubId)
      }}>
      {cell.getValue()}
    </Typography>
  )

  const playerAgeRenderer = ({ row }) => (
    <Fragment>
      <span>{row.original.age}</span>&nbsp;<span style={{ opacity: .65 }}>{'Y'}</span>&nbsp;<span style={{ opacity: .65 }}>{row.original.months}</span>&nbsp;<span style={{ opacity: .65 }}>{'M'}</span>
    </Fragment>
  )

  const withdrawalClauseRenderer = ({ cell }) => {
    let value = cell.getValue()
    if (value === null || value === undefined)
      return null
    if (value === 0)
      return 'NO'
    let formattedValue = Math.round(value).toLocaleString('en-US')
    return (
      <Fragment>
        <span>{formattedValue}</span>
      </Fragment>
    )
  }

  const clubLogoRender = ({ row, cell }) => {
    const handleClick = () => {
      navigate('/clubs/' + row.original.currentClubId)
    };
  
    return (
      <Tooltip title={row.original.currentClubName} placement='right-start'>
          <img
            onClick={handleClick}
            src={'/img/clubs/logos/' + (cell.getValue() ? cell.getValue() : 'OLY') + '.png'}
            height='32px'
            width='25px'
            alt={row.original.masterClubName}
            style={{ cursor: 'pointer' }}
          />
      </Tooltip>
    );
  }

  const masterClubLogoRender = ({ row, cell }) => {
    const handleClick = () => {
      navigate('/clubs/' + row.original.masterClubId)
    };
  
    return (
      <Tooltip title={row.original.masterClubName} placement='right-start'>
          <img 
            onClick={handleClick}
            src={'/img/clubs/logos/' + (cell.getValue() ? cell.getValue() : 'OLY') + '.png'}
            height='32px'
            width='25px'
            style={{ cursor: 'pointer' }}
          />
      </Tooltip>
    );
  }

  const countColumn = {
    header: 'count',
    maxSize: 50,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', className: 'StdCountCell', sx: { color: '#FFFFFF', backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdCountCell' },
    Header: () => <></>,
    Cell: ({ cell }) => <StdRowCount cell={cell} rowId='playerId' />
  }

  const sortWatchlistColumn = {
    accessorKey: 'position',
    header: 'position',
    maxSize: 30,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', className: 'StdCountCell', sx: { backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdCountCell' },
    Header: <></>,
    Cell: ({ cell }) => <WatchlistPlayerPosition params={{ id: cell.row.id, value: cell.getValue() }} onChangePlayerPosition={props.onChangePlayerPosition} />
  }

  const nameColumn = {
    header: 'name',
    accessorKey: 'name',
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'left', sx: { backgroundColor: '#15222B' } },
    muiTableBodyCellProps: { align: 'left', className: 'StdNameCell', sx: { borderRightWidth: 4 } },
    Header: () => (
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
        {props.isDynamicWatchlist ?
          null
          :
          <StdNameSearchField ref={ref} className={props.isWatchlist ? 'StdWPlayersNameSearchField' : 'StdPlayersNameSearchField'} onSearch={props.filterByName} />}
      </Box>
    ),
    Cell: ({ cell }) => (
      <Typography sx={{ color: '#F4D35E', fontSize: '1.5rem', fontWeight: 600, cursor: 'pointer' }}
        onClick={() => navigate('/players/' + cell.row.id)}>
        {cell.getValue()}
      </Typography>
    )
  }

  const addToWatchlistColumn = {
    header: 'Watchlist',
    accessorKey: 'watchlist',
    maxSize: 30,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'center', className: 'StdAddWatchlistCell', sx: { backgroundColor: '#15222B !important' } },
    muiTableBodyCellProps: { align: 'center', className: 'StdAddWatchlistCell' },
    Header: () => <></>,
    Cell: ({ cell }) => <AddToWatchlistCell cell={cell} popperRef={popperRef} />
  }

  const editWatchlistColumn = {
    header: 'edit',
    accessorKey: 'edit',
    maxSize: 30,
    enableSorting: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableColumnDragging: false,
    muiTableHeadCellProps: { align: 'center', className: 'StdAddWatchlistCell', sx: { backgroundColor: '#15222B !important' } },
    muiTableBodyCellProps: { align: 'center', className: 'StdAddWatchlistCell' },
    Header: () => <></>,
    Cell: ({ row }) => (
      <Fragment>
        <Tooltip title={<TranslatedContent code='removeFromWatchlist' defaultValue='Remove from watchlist' />} placement='right'>
          <IconButton onClick={() => props.removePlayerFromWatchlist(row.id)} sx={{ mx: .5 }}>
            <RemoveCircleIcon fill='#707070' width='20px' height='20px' />
          </IconButton>
        </Tooltip>
      </Fragment>
    )
  }
 
  const generalColumnsLoan = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'Player Role', accessorKey: 'currentRole', size: 170, Cell: roleGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Age', accessorKey: 'age', size: 150, Cell: playerAgeRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Passport', accessorKey: 'nationalityCode', size: 180, Cell: countryGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player National Team', accessorKey: 'nationalTeamPlayer', size: 160, Cell: ({ cell }) => booleanRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Registering', accessorKey: 'currentClubName', minSize: 200, Cell: masterClubNameRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Owner', accessorKey: 'masterClubName', minSize: 200, Cell: masterClubNameRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Start Contract', accessorKey: 'startContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    props?.title === "loanedPlayers" ? { header: 'Player Start Master Contract', accessorKey: 'masterClubStartContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } } : "",
    { header: 'Player End Contract', accessorKey: 'masterClubEndContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const generalColumns = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'Player Role', accessorKey: 'currentRole', size: 170, Cell: roleGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Age', accessorKey: 'age', size: 150, Cell: playerAgeRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Passport', accessorKey: 'nationalityCode', size: 180, Cell: countryGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player National Team', accessorKey: 'nationalTeamPlayer', size: 210, Cell: ({ cell }) => booleanRenderer({ value: cell.getValue() }), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Registering', accessorKey: 'currentClubName', minSize: 200, Cell: masterClubNameRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Owner', accessorKey: 'masterClubName', minSize: 200, Cell: masterClubNameRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Start Contract', accessorKey: 'startContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player End Contract', accessorKey: 'endContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const performanceColumns = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'Player Effectiveness', accessorKey: 'effectiveness', size: 210, Cell: effectivenessVariationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Played Minutes', accessorKey: 'minutes', size: 190, Cell: integerNumberGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Volatility', accessorKey: 'latestMicroVolatility', size: 170, Cell: percNumberGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Uniqueness', accessorKey: 'latestUniqueness', size: 190, Cell: tierGridRenderer(100), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Experience', accessorKey: 'experience', size: 190, Cell: decimalNumberGridRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Effectiveness Rank', accessorKey: 'rankByEffectiveness', size: 120, enableSorting: false, Cell: tierGridRenderer(numOfPlayers), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player League Tier', accessorKey: 'currentLeagueCluster', size: 180, Cell: tierGridRenderer(numOfLeagueGroups), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn', sx: { borderLeftWidth: 0 } } },
    //{ header: 'Played Matches', accessorKey: 'playedMatches', size: 180, enableSorting: false, Cell: () => 'COMING SOON', Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfLeagueGroups, numOfPlayers])

  const valueColumns = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'Player Fair Value', accessorKey: 'potentialPrice', size: 200, Cell: potentialPriceVariationRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Expected Price', accessorKey: 'perceivedExpectedPrice', size: 230, Cell: expectedPriceVariationRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Transfer Price', accessorKey: 'transferPrice', size: 230, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Residual Value', accessorKey: 'residualValue', size: 230, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Book Value', accessorKey: 'residualBookValue', size: 210, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Withdrawal Clause', accessorKey: 'withdrawalClause', size: 230, Cell: withdrawalClauseRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player End Contract', accessorKey: 'endContract', size: 210, Cell: dateGridFormatter, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    { header: 'Player Rank By Value', accessorKey: 'rankByValue', size: 180, enableSorting: false, Cell: tierGridRenderer(numOfPlayers), Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'center', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [numOfPlayers])

  const salaryColumns = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'Player Fair Salary', accessorKey: 'salaryOptimal', size: 210, Cell: salaryOptimalVariationRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Potential Salary', accessorKey: 'salaryPotential', size: 200, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Net Salary', accessorKey: 'salaryNet', size: 200, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Gross Salary', accessorKey: 'salaryGross', size: 220, Cell: decimalNumberGridRenderer, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Fair Salary Abs Deviation', accessorKey: 'salaryAbsoluteDeviation', size: 220, Cell: salaryDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Club Tier Avg Salary Abs Deviation', accessorKey: 'absClubCusterAvgSalaryDeviation', size: 190, enableSorting: false, Cell: clubClusterAvgSalaryDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player League Avg Salary Abs Deviation', accessorKey: 'absLeagueAvgSalaryDeviation', size: 210, enableSorting: false, Cell: leagueAvgSalaryDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'Player Club Avg Salary Abs Deviation', accessorKey: 'absClubAvgSalaryDeviation', size: 190, enableSorting: false, Cell: clubAvgSalaryDeviationRenderer, Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const kpiColumns = useMemo(() => [
    props.isWatchlist ? sortWatchlistColumn : countColumn,
    props.isWatchlist ? editWatchlistColumn : addToWatchlistColumn,
    nameColumn,
    { header: 'playerCapitalGain', accessorKey: 'capitalGain', size: 210, Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'annualCost', accessorKey: 'annualCost', size: 210, Header: currencyHeaderRenderer, Cell: decimalNumberGridRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'playerCostPerMinute', accessorKey: 'costPerMinute', cell: ({ cell }) => {return cell.getValue().toFixed(3)},size: 200, Header: currencyHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'costImpactOnRevenues', accessorKey: 'costImpactOnRevenues', size: 200, Header: currencyHeaderRenderer, Cell: percentageFormatter, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'salaryImpactOnSalaries', accessorKey: 'salaryImpactOnSalaries', size: 220, Header: currencyHeaderRenderer, Cell: percentageFormatter, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'playerClubRelativePlayerPerformance', accessorKey: 'clubRelativePlayerPerformance', Cell: percentageFormatter, size: 220,  Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    { header: 'playerLeagueRelativePlayerPerformance', accessorKey: 'leagueRelativePlayerPerformance', Cell: percentageFormatter, size: 190, enableSorting: false,  Header: translatedHeaderRenderer, muiTableHeadCellProps: { align: 'center', className: 'StdHeader' }, muiTableBodyCellProps: { align: 'right', className: 'StdColumn' } },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  const userToken = useSelector(state => state.user.token)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [players, setPlayers] = useState(props.players)
  const [columns, setColumns] = useState(generalColumns)
  const [columnVisibility, setColumnVisibility] = useState({ count: !props.isRecommendedWatchlist, watchlist: !props.isWatchlist, edit: props.isWatchlist === true })
  const [columnOrder, setColumnOrder] = useState(columns.map(c => c.accessorKey))

  const [tabState, setTabState] = useState('general')
  const [filterOpen, setFilterOpen] = useState(false)

  const isBreakpointSm = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const [wName, setWName] = useState(props.title)
  const [wPosition, setWPosition] = useState(props.watchlistPosition)
  const [wNameEditMode, setWNameEditMode] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      let headerWrappers = document.getElementsByClassName('Mui-TableHeadCell-Content-Wrapper')
      for (let i = 0; i < headerWrappers.length; i++) {
        headerWrappers.item(i).removeAttribute('title')
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [columns])

  useEffect(() => {
    setColumnOrder(columns.map(c => c.accessorKey))
  }, [columns])

  useEffect(() => {
    setNumOfPlayers(playersTotal)
  }, [playersTotal])

  useEffect(() => {
    setNumOfLeagueGroups(leagueGroups.length)
  }, [leagueGroups])

  useEffect(() => {
    if (!props.disableFilters) {
      setFilterOpen(props.filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterOpen])

  useEffect(() => {
    if (!props.disableFilters) {
      props.setFilterOpen(filterOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOpen])

  useEffect(() => {
    if (isBreakpointSm) {
      setColumnVisibility(prevState => ({ ...prevState, count: true }))
    } else {
      setColumnVisibility(prevState => ({ ...prevState, count: false }))
    }
  }, [isBreakpointSm])

  useEffect(() => {
    if (playersTotal === 0) {
      statisticsService.getStatistics(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeaguesTotal(res.data.data.numOfLeagues))
            dispatch(setClubsTotal(res.data.data.numOfClubs))
            dispatch(setPlayersTotal(res.data.data.numOfPlayers))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(leagueGroups) || leagueGroups.length === 0) {
      filtersService.getLeagueGroups(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setLeagueGroups(res.data.data.groups))
          }
        })
        .catch(err => console.error(err))
    }
    if (!Array.isArray(clubClusters) || clubClusters.length === 0) {
      filtersService.getClubClusters(userToken, dispatch)
        .then(res => {
          if (res.status === 200) {
            dispatch(setClubClusters(res.data.data.clusters))
          }
        })
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken])

  useEffect(() => {
    setPlayers(props.players)
  }, [props.players])

  useEffect(() => {
    if (props.isWatchlist || props.isDynamicWatchlist) {
      setWName(props.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title])

  useEffect(() => {
    setWPosition(props.watchlistPosition)
  }, [props.watchlistPosition])

  useEffect(() => {
    let newColumns = []
    switch (tabState) {
      case 'general':
        newColumns = props.title === 'loanedPlayers' ? generalColumnsLoan : generalColumns;
        break
      case 'performance':
        newColumns = performanceColumns
        break
      case 'value':
        newColumns = valueColumns
        break
      case 'salary':
        newColumns = salaryColumns
        break
      case 'kpi':
        newColumns = kpiColumns
        break
      default:
        console.warn('[StdPlayersTable] unhandled tab state selected: ' + tabState)
        break
    }
    setColumns(newColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState, numOfPlayers, numOfLeagueGroups])

  const toggleFilterOpen = (e) => {
    e.preventDefault()
    setFilterOpen(prevState => !prevState)
  }

  const handleTabClick = (e) => {
    switch (e.target.id) {
      case 'generalBtn':
        setTabState('general')
        break
      case 'performanceBtn':
        setTabState('performance')
        break
      case 'valueBtn':
        setTabState('value')
        break
      case 'salaryBtn':
        setTabState('salary')
        break
      case 'kpiBtn':
        setTabState('kpi')
        break 
      default:
        console.warn('[StdPlayersTable] unhandled button clicked: ' + e.target.id)
        break
    }
  }

  const handleSortingChange = (sorting) => {
    if (props.isWatchlist) {
      let currentSorting = sorting(props.sorting)
      if (currentSorting && currentSorting.length > 0) {
        setColumnVisibility({ ...columnVisibility, position: false })
      } else {
        setColumnVisibility({ ...columnVisibility, position: true })
      }
    }
    if (props.onSortingChange) props.onSortingChange(sorting)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', ...props.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {props.isWatchlist || props.isDynamicWatchlist ?
          <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: 'unset' }, justifyContent: { xs: 'space-between', sm: 'flex-start' } }}>
            <Box sx={{ display: 'flex' }}>
              <TextField
                type='number'
                variant='standard'
                size='small'
                disabled={props.isDefaultWatchlist}
                value={wPosition}
                onChange={(e) => { setWPosition(e.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    props.onChangeWatchlistPosition(Number(e.target.value))
                  }
                }}
                sx={{ display: props.isDynamicWatchlist ? 'none' : 'block', mr: 2 }}
                InputProps={{
                  sx: {
                    color: '#000000',
                    backgroundColor: '#FFFFFF',
                    fontWeight: 500,
                    width: 30,
                    border: '1px solid #FFFFFF',
                    outline: 0,
                    '&::before': { border: 0 },
                    '&::after': { border: 0 },
                    '&:hover:not(.Mui-disabled)::before': { borderBottom: 0 },
                    'input': { textAlign: 'center' }
                  }
                }}
              />
              {wNameEditMode ?
                <TextField
                  autoFocus
                  variant='standard'
                  size='small'
                  value={wName}
                  onChange={(e) => setWName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      props.onChangeWatchlistName(wName)
                      setWNameEditMode(false)
                    }
                  }}
                  sx={{ maxWidth: 300 }}
                  InputProps={{
                    sx: {
                      color: '#A1CBC2',
                      fontSize: '2rem',
                      backgroundColor: '#31495A',
                      border: '1px solid #A1CBC2',
                      outline: 0,
                      '&::before': { border: 0 },
                      '&::after': { border: 0 },
                      '&:hover:not(.Mui-disabled)::before': { borderBottom: 0 }
                    }
                  }}
                  inputProps={{ maxLength: 30 }}
                />
                :
                <Box sx={{ mr: { xs: 1, md: 5 } }}>
                  <Typography
                    sx={{
                      color: '#A1CBC2',
                      fontSize: '2rem',
                      fontWeight: 600,
                      textTransform: 'uppercase'
                    }}>
                    {wName}
                  </Typography>
                </Box>}
            </Box>
            <Box sx={{ display: props.isDynamicWatchlist || wNameEditMode ? 'none' : 'flex', alignItems: 'center' }}>
              <Tooltip title={<TranslatedContent code='editWatchlistName' defaultValue='Edit Watchlist Name' />}>
                <IconButton sx={{ mr: .5 }} onClick={() => setWNameEditMode(true)}>
                  <EditCircleIcon fill='#04A8A3' width='24px' height='24px' />
                </IconButton>
              </Tooltip>
              <Tooltip title={<TranslatedContent code='removeWatchlist' defaultValue='Remove Watchlist' />}>
                <IconButton sx={{ mx: .5 }} onClick={props.removeWatchlist}>
                  <RemoveCircleIcon fill='#707070' width='24px' height='24px' />
                </IconButton>
              </Tooltip>
              <Tooltip title={<TranslatedContent code='pinWatchlist' defaultValue='Pin Watchlist' />}>
                <Box component='span'>
                  <IconButton disabled={props.isDefaultWatchlist}
                    sx={{
                      '& .pinInternal': {
                        fill: props.isDefaultWatchlist ? '#000000' : '#F4D35E'
                      },
                      '& .pinCircle': {
                        fill: props.isDefaultWatchlist ? '#F4D35E' : '#000000',
                        stroke: props.isDefaultWatchlist ? '#000000' : '#F4D35E'
                      },
                      mx: .5
                    }}
                    onClick={props.onMarkWatchlistAsDefault}>
                    <PinCircleIcon width='24px' height='24px' />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ display: wNameEditMode ? 'flex' : 'none', alignItems: 'center' }}>
              <IconButton sx={{ mx: .5 }} onClick={() => {
                props.editWatchlistName(wName)
                setWNameEditMode(false)
              }}>
                <CheckCircleIcon fill='#04A8A3' width='24px' height='24px' />
              </IconButton>
              <IconButton sx={{ mx: .5 }} onClick={() => {
                setWName(props.title)
                setWNameEditMode(false)
              }}>
                <CancelCircleIcon fill='#707070' width='24px' height='24px' />
              </IconButton>
            </Box>
          </Box>
          :
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{
              color: '#FFFFFF',
              fontSize: '3rem',
              fontWeight: 600,
              textTransform: 'uppercase',
              mr: 2
            }}>
              <TranslatedContent code={props.title ? props.title : 'players'} defaultValue='Players' />
            </Typography>
            <Tooltip title={<TranslatedContent code='playersInfo' description />}>
              <InfoIcon fill='#FFFFFF' height='20px' />
            </Tooltip>
          </Box>}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: { xs: 'none', xl: 'flex' }, flexDirection: 'row' }}>
            <Button id='generalBtn' variant='stdTab' className={tabState === 'general' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='general' defaultValue='General' />
            </Button>
            <Button id='performanceBtn' variant='stdTab' className={tabState === 'performance' ? 'stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='performance' defaultValue='Performance' />
            </Button>
            <Button id='valueBtn' variant='stdTab' className={tabState === 'value' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='value' defaultValue='Value' />
            </Button>
            <Button id='salaryBtn' variant='stdTab' className={tabState === 'salary' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='salary' defaultValue='Salary' />
            </Button>
            <Button id='kpiBtn' variant='stdTab' className={tabState === 'kpi' ? ' stdActive' : ''} onClick={handleTabClick} sx={{ mx: 2 }}>
              <TranslatedContent code='kpi' defaultValue='Kpi' />
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block', xl: 'none' } }}>
            <TabSelect tabState={tabState} setTabState={setTabState} />
          </Box>
          <Box sx={{ display: props.disableFilters ? 'none' : 'block' }}>
            <IconButton onClick={toggleFilterOpen} sx={{ backgroundColor: '#FFFFFF', borderRadius: '50%', p: 1, ml: 3, '&:hover': { backgroundColor: '#FFFFFF' } }}>
              <TuneIcon sx={{ color: '#04A8A3' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: props.isWatchlist ? '#787878' : '#FFFFFF', height: 2, width: '100%', mt: 1, mb: { xs: 2, sm: 4 } }} />
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, width: '100%', justifyContent: 'center', mb: 2 }}>
        <TabSelect tabState={tabState} setTabState={setTabState} />
      </Box>
      <Box sx={{ display: props.isWatchlist ? 'flex' : 'none', flexDirection: { xs: 'column', md: 'row' }, width: '100%', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-end' }, mb: { xs: 2, md: 1 } }}>
        <Typography sx={{ color: '#04A8A3', fontSize: '1.8rem', fontWeight: 600, textTransform: 'uppercase', mr: { xs: 0, md: 3 }, mb: { xs: 1, lg: 0 } }}>
          <TranslatedContent code='addToWatchlist' defaultValue='Add to watchlist' />
        </Typography>
        <AddToWatchlistAutocomplete
          watchlistPlayers={props.watchlistPlayers}
          onAddToWatchlist={props.addPlayerToWatchlist}
          sx={{
            width: { xs: 200, sm: 250 },
            color: '#A1CBC2',
            fontSize: '2rem',
            backgroundColor: '#31495A',
            border: '1px solid #A1CBC2',
            outline: 0,
            '&::before': { border: 0 },
            '&::after': { border: 0 },
            '&:hover:not(.Mui-disabled)::before': { borderBottom: 0 }
          }} />
      </Box>
      <StdDataGrid
        columns={columns}
        data={players}
        rowId={(player) => player.playerId}
        rowCount={props.rowCount}
        enablePagination={props.enablePagination}
        manualPagination={props.manualPagination}
        page={props.page}
        pageSize={props.pageSize}
        onPaginationChange={props.onPaginationChange}
        sorting={props.sorting}
        onSortingChange={handleSortingChange}
        columnVisibility={columnVisibility}
        columnOrder={columnOrder}
        onColumnOrderChange={setColumnOrder}
        enableBottomToolbar={props.enableBottomToolbar} />
      <WatchlistPopper ref={popperRef} />
    </Box>
  )
})

export default StdPlayersTable